<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="item in list" :key="item.notice_id" class="list-item" @click="onNavigator({path:'/notice/detail?notice_id='+item.notice_id})">
        <div class="list-item-time">
          {{ item.notice_at }}
        </div>
        <div class="list-item-inner">
          <div class="list-item-title">{{ item.notice_title }} <span v-if="item.notice_is_read == 0" class="list-item-dot" /></div>
          <div class="list-item-info">{{ item.notice_brief }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'Notice',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.notice_index({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .container{
    padding: 20px 15px;
  }
  .list{

  }
  .list-item{
    margin-bottom: 20px;
    &-time{
      text-align: center;
      font-size: 10px;
      color: #999;
      margin-bottom: 6px;
    }
    &-inner{
      padding: 20px 15px;
      background: #fff;
      border-radius: 8px;
    }
    &-title{
      font-size: 16px;
      color: #333;
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
    }
    &-info{
      font-size: 14px;
      color: #666;
    }
    &-dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #ee0a24;
      border-radius: 50%;
      margin-left: 5px;
    }
  }
</style>

